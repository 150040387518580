<template>
  <div class="content">
    <h1 class="title title--big title--indent title--color">{{ pageTitle }}</h1>
    <div v-if="statsLoaded" class="statistics">
      <h2 v-if="!$store.state.regionalAdmin" class="title title--indent">Заявки на выпуск карты</h2>
      <div v-if="!$store.state.regionalAdmin" class="statistics__items">
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.new_cards }}</div>
            <p class="statistics__desc">Новые</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.payed_cards }}</div>
            <p class="statistics__desc">Оплачены</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.approved_cards }}</div>
            <p class="statistics__desc">Подтверждены</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.issued_cards }}</div>
            <p class="statistics__desc">Выданы</p>
          </div>
        </div>
      </div>
      <h2 v-if="!$store.state.regionalAdmin" class="title title--indent">Карты</h2>
      <div v-if="!$store.state.regionalAdmin" class="statistics__items">
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.eyca_count }}</div>
            <p class="statistics__desc">Свободных номеров EYCA</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.card_all }}</div>
            <p class="statistics__desc">Всего карт</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.card_is_active }}</div>
            <p class="statistics__desc">Активных карт</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--abort">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.card_not_active }}</div>
            <p class="statistics__desc">Неактивных карт</p>
          </div>
        </div>
      </div>
      <h2 class="title title--indent">Партнеры</h2>
      <div class="statistics__items">
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.partners.active }}</div>
            <p class="statistics__desc">Активные</p>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.partners.checking }}</div>
            <p class="statistics__desc">На проверке</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.partners.new }}</div>
            <p class="statistics__desc">Новые</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--archive">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.partners.archive }}</div>
            <p class="statistics__desc">Архив</p>
          </div>
        </div>
      </div>
      <h2 class="title title--indent">Предложения активных партнеров</h2>
      <div class="statistics__items">
        <div class="statistics__item">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.discounts.discounts + stats.discounts.offers}}</div>
            <p class="statistics__desc">Всего</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--success">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.discounts.discounts }}</div>
            <p class="statistics__desc">Скидок</p>
          </div>
        </div>
        <div class="statistics__item statistics__item--neutral">
          <div class="statistics__info">
            <div class="statistics__count">{{ stats.discounts.offers }}</div>
            <p class="statistics__desc">Спецпредложений</p>
          </div>
        </div>
      </div>
      <div v-if="!$store.state.regionalAdmin" class="statistics__holder">
        <div class="statistics__party">
          <h2 class="title">График выдачи карт по месяцам</h2>
          <div class="statistics__dates">{{ chartData.labels[0] }} – {{ chartData.labels[chartData.labels.length - 1] }}</div>
        </div>
        <div class="statistics__graph">
          <Chart :chartdata="chartData" :options="chartOptions" :height="chartHeight" />
        </div>
      </div>
      <div class="statistics__holder">
        <div class="statistics__party">
          <h2 class="title">График подключения партнеров по месяцам</h2>
          <div class="statistics__dates">{{ chartDataPartners.labels[0] }} – {{ chartDataPartners.labels[chartDataPartners.labels.length - 1] }}</div>
        </div>
        <div class="statistics__graph">
          <Chart :chartdata="chartDataPartners" :options="chartOptions" :height="chartHeight" />
        </div>
      </div>
    </div>
    <div class="loading loading--content" id="loading" v-if="!statsLoaded">
      <div class="loading__body">
        <div class="effect-1 loading__effects"></div>
        <div class="effect-2 loading__effects"></div>
        <div class="effect-3 loading__effects"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/Chart';

export default {
  name: 'Statistics',
  props: ['pageTitle'],
  components: {
    Chart
  },
  data() {
    return {
      stats: {},
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgba(43, 147, 231, 0.15)',
            borderColor: '#2B93E7',
            pointBackgroundColor: '#2B93E7'
          }
        ]
      },
      chartDataPartners: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgba(43, 147, 231, 0.15)',
            borderColor: '#2B93E7',
            pointBackgroundColor: '#2B93E7'
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      },
      chartHeight: 300,
      statsLoaded: false,

    };
  },
  mounted() {
    if (this.$store.state.regionalAdmin) {
      this.fetchRegionStatistics();
    } else {
      this.fetchStatistics();
    }
  },
  methods: {
    fetchStatistics() {
      this.$store.dispatch('admin/GET_STATISTICS')
        .then(response => {
          this.stats = response.data;
          const labels = Object.keys(response.data.card_by_month);
          const partnerLabels = Object.keys(response.data.partners_by_month);
          labels.forEach(label => {
            this.chartData.labels.push(label.slice(0, 10));
          });
          partnerLabels.forEach(label => {
            this.chartDataPartners.labels.push(label.slice(0, 10));
          });
          this.chartData.datasets[0].data = Object.values(response.data.card_by_month);
          this.chartDataPartners.datasets[0].data = Object.values(response.data.partners_by_month);
          this.statsLoaded = true;
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: `Ошибка получения статистики: ${ error.message }`
          });
        });
    },
    fetchRegionStatistics() {
      this.$store.dispatch('admin/GET_REGION_STATISTICS')
        .then(response => {
          this.stats = response.data;
          const partnerLabels = Object.keys(response.data.partners_by_month);
          partnerLabels.forEach(label => {
            this.chartDataPartners.labels.push(label.slice(0, 10));
          });
          this.chartDataPartners.datasets[0].data = Object.values(response.data.partners_by_month);
          this.statsLoaded = true;
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: `Ошибка получения статистики: ${ error.message }`
          });
        });
    }
  }
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.statistics

.statistics__items
  display: grid
  grid-template-columns: repeat(auto-fill, 292px)
  column-gap: 15px
  row-gap: 20px
  margin-bottom: 40px

.statistics__item
  min-height: 100px
  padding: 15px
  border-radius: 7px
  background-image: linear-gradient(256.92deg, #7EC7FF 0.85%, #1D87DD 98.53%)
  box-shadow: 0 2px 6px rgba(43, 147, 231, 0.25)

.statistics__item--abort
  background-image: linear-gradient(256.92deg, #FFBDC8 0.85%, #EA5467 98.53%)

.statistics__item--success
  background-image: linear-gradient(256.21deg, #BDE9C8 0%, #38BF4E 96.91%)

.statistics__item--neutral
  background-image: linear-gradient(256.21deg, #FADCB6 0%, #FF9416 96.91%)

.statistics__item--archive
  background-image: linear-gradient(256.21deg, #DDDDDD 0%, #AAAAAA 96.91%)

.statistics__count
  color: #fff
  font-size: 24px
  font-weight: 700

.statistics__desc
  color: #fff
  font-size: 14px

.statistics__holder
  padding: 40px
  border-radius: 10px
  background-color: #F8FDFF

.statistics__party
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 40px

.statistics__dates
  color: $color-theme
  font-size: 16px
  font-weight: 600
</style>